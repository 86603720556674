import React from 'react';
import Style from './gsa-scheduled-contracts.module.scss';
import { graphql, Link } from 'gatsby';
import PageWithHeader from '../components/pageWithHeader';
// @ts-ignore
import gsa from '../../static/images/gsa-fast.png';

export default ({}) => {
    // const query = graphql`
    // query data {}
    // `;

    return (
        <PageWithHeader
            title="GSA Scheduled Contracts"
            description="GSA Scheduled Contracts">
            <article className={Style.scheduledContractsPage}>
                <div className={Style.header}>
                    <div className={Style.headerImage}><img src={gsa}></img></div>
                    <div className={Style.headerContent}>
                        <h2>Centeva helps federal agencies enhance the overall effectiveness of their acquisition lifecycle framework operations.</h2>
                        <p>We are committed to helping our clients in their efforts to meet increasing demands to deliver.</p>
                    </div>
                </div>
                <div className={Style.contracts}>
                    <div className={Style.contract}>
                        <h3>GSA 8(A) STARS II</h3>
                        <h4>Contract GS-00Q-17GWD-2328</h4>
                        <p>8(a) STARS II, a small business set-aside GWAC, provides flexible access to customized IT solution from a large, diverse pool of 8(a) industry partners. Our contract manager, Mathew Thomas, can be reached by phone at 801-967-0077 or by email at matthew.thomas@centeva.com.</p>
                        <ul>
                            <li>Four functional area to meet your mission requirements</li>
                            <li>Directed task orders up to $4 Million each</li>
                            <li>Access to multiple types of task orders</li>
                        </ul>
                    </div>
                    <div className={Style.contract}>
                        <h3>GSA IT SCHEDULE 70</h3>
                        <h4>Contract GS-QS-35F-0253Y</h4>
                        <p>IT Schedule 70 delivers federal, state, and local customer agencies the tools and expertise needed to shorten procurement cycles, ensure compliance, and obtain the best value for innovative technology products, services, and solutions.</p>
                        <ul>
                            <li>Wide variety of products and services</li>
                            <li>Competitive prices and discounts</li>
                            <li>Flexible contracting and streamlined ordering</li>
                            <li>Compliance with federal cybersecurity guidelines</li>
                            <li>Access to small business credit</li>
                        </ul>
                    </div>
                </div>
                <div className={Style.contractFull}>
                    <h3>GSA Professional Services Schedule (PSS)</h3>
                    <h4>Contract GS-00F-132DA</h4>
                    <p>The Professional Services Schedule (PSS) in an indefinite delivery/indefinite quantity (IDIQ) multiple award schedule, providing direct access to simple or complex fixed price or labor hour professional services.</p>
                    <div className={Style.contractFullLists}>
                        <ul>
                            <li>Use simplified acquisition procedures to issue a Request for Quotation (RFQ) using either your agency's solicitation system or GSA's eBuy solicitation system, or issue an RFQ directly to contractors using your agency's procedures.</li>
                            <li>Allow unique Contractor Team Arrangements to bring a wide variety of industry partners to the negotiating table.</li>
                            <li>Customize terms and conditions in task orders - or through Blank Purchase Agreements - to meet specific requirements.</li>
                        </ul>
                        <ul>
                            <li>Be assured that required FAR terms and conditions are incorporated into the contract.</li>
                            <li>Ensure adequate participation by small businesses, including setting aside RFQs for various socioeconomic subgroups.</li>
                            <li>Save time by using the streamlined procurement procedures of FAR Subpart 8.4.</li>
                        </ul>
                    </div>
                </div>
            </article>
            <div className={Style.articleFooter}>
                <h3>Powerful Solution. Effective Support.</h3>
                <Link to="/contact/"><button>Contact us</button></Link>
            </div>
        </PageWithHeader>
    );
};
